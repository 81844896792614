<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs
        v-if="show351"
        v-model="recordTab"
        type="card"
        @tab-click="handleTabFun"
      >
        <el-tab-pane label="订单欠费查询" name="order" />
        <el-tab-pane label="已缴欠费查询" name="pay" />
      </el-tabs>
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item v-if="show351" :label="$t('searchModule.Parking_Order_ID')">
                <a-input
                  v-model="formInline.parkRecordId"
                  placeholder="请输入"
                  :maxlength="30"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <a-select
                  filterable
                  :data="roleList"
                  modifier="trim"
                  size="15"
                  label="operationName"
                  value="operationId"
                  v-model="formInline.operationId"
                >
                  <el-option label="全部" value=""></el-option>
                </a-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <a-auto-complete
                  v-model="formInline.parkName"
                  placeholder="请输入道路名称"
                  valueKey="parkName"
                  @change="handleInputPark"
                  :searchQuery="{
                    areaIds,
                    operationId: formInline.operationId,
                    dataSource: 1,
                    slaveRelations: '0,1',
                  }"
                  @select="handleSelectPark"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-select
                  filterable
                  v-model="formInline.parkType"
                  :data="parkType"
                  label="desc"
                  value="code"
                  modifier="trim"
                  size="15"
                >
                  <el-option label="全部" value="-1"></el-option>
                </a-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <a-auto-complete
                  class="inline-input"
                  size="12"
                  v-model="formInline.plateNumber"
                  :asyncState="true"
                  placeholder="请输入内容"
                  valueKey="plateNumber"
                  @fetchSearch="querySearchAsync"
                  @select="handleSelect"
                />
              </el-form-item>

              <!-- 3.5.1功能 -->
              <el-form-item label="欠费区间" v-if="show351">
                <div class="record-from-to">
                  <zInput
                    v-model="formInline.fromRecord"
                    valueType="plus"
                    placeholder="请输入"
                    :fixed="2"
                    :maxlength="30"
                  />
                  <span>至</span>
                  <zInput
                    v-model="formInline.toRecord"
                    valueType="plus"
                    placeholder="请输入"
                    :fixed="2"
                    :maxlength="30"
                  />
                </div>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Berth_number')">
                <a-input
                  v-model="formInline.berthCode"
                  placeholder="请输入泊位号"
                  :maxlength="30"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
                <a-select
                  filterable
                  v-model="formInline.entryExitType"
                  :data="ExitTypeList"
                  label="desc"
                  value="code"
                  modifier="trim"
                  size="10"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.time_slot')">
                <a-time-picker
                  @timeChange="(...params) => timeChange(params, 'period')"
                  ref="timeRangePicker"
                  :showRange="true"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_Time')" v-if="recordTab === 'pay'">
                <a-time-picker
                  @timeChange="(...params) => timeChange(params, 'pay')"
                  ref="timePickerPay"
                  :showRange="true"
                  :defalutDate="payData"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                @exportFile="handleExportFile"
                v-if="$route.meta.authority.button.export"
                :exportData="exportData"
              />
            </div>
          </div>
          <tabCard :cardLists="cardLists"></tabCard>
        </el-form>
      </div>
      <!-- <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">停车记录</div>
          <span class="unit"
            ><span class="bold-wrap">{{ totalObj.parkRecordNum || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">欠费金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{ totalObj.money | formatValue(tenTh, "number") }}
            </span>
            {{ totalObj.money | formatValue(tenTh, "unit") }}
          </span>
        </div>
      </div> -->
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template v-for="{ prop, label, width, formatter } in tableCols">
            <el-table-column
              v-if="showElFun(prop)"
              align="center"
              :prop="prop"
              :label="label"
              :width="width"
              :key="prop"
              :formatter="formatter"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span>{{ getTableValue({ row, prop, formatter }) }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        class="dialogVisible"
        title="欠费提醒配置"
        :visible.sync="dialogVisible"
        width="450px"
      >
        <el-form
          ref="form"
          @submit.native.prevent="submit"
          :rules="rules"
          :model="arrearsForm"
          label-width="120px"
          style="width: 80%"
        >
          <el-form-item label="欠费提醒金额" prop="debtMoney">
            <el-input
              width="120px"
              maxlength="7"
              placeholder="请输入欠费提醒金额"
              type="text"
              v-model="arrearsForm.debtMoney"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { merge, cloneDeep, get } from "lodash";
import { mapActions, mapState } from "vuex";
import { dateFormat, setIndex, onlyNumber } from "@/common/js/public.js";
import {
  aAutoComplete,
  aSelect,
  aTimePicker,
  aInput,
} from "@/components/bussinessArrears";
import exportFile from "@/components/exportFile/exportFile.vue";
import tabCard from "@/components/tabCard/index.vue";
export default {
  name: "arrearsRecordTotal",
  components: {
    aInput,
    aAutoComplete,
    exportFile,
    aTimePicker,
    aSelect,
    tabCard,
  },
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输十一个字符"));
      }
    };
    return {
      cardLists: [
        { title: "停车记录", number: 0, unit: "个" },
        { title: "欠费金额", number: 0, unit: "元" },
      ],
      recordTab: "order",
      show351: false, // 3.5.1开关，待相应接口可调式将相关去掉
      defalutDate: [], // 时间段
      payData: [], // 3.5.1 支付时间
      onlyNumber,
      rules: {
        debtMoney: [
          { required: true, message: "欠费提醒金额不能为空" },
          {
            validator: (rule, value, callback) => {
              this.arrearsForm.debtMoney = this.onlyNumber(value);
              callback();
            },
            trigger: "change",
          },
        ],
      },
      dialogVisible: false,
      arrearsForm: {
        debtMoney: "",
      },
      state2: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      roleList: [],
      plateList: [],
      ExitTypeList: [],
      merchantList: [],
      berthList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index", // TODO
          label: this.$t("list.index"),
          width: "70",
        },
        {
          prop: "parkRecordId", // TODO
          label: this.$t("list.Parking_Order_ID"),
          width: "180",
        },
        {
          prop: "cityName", // TODO
          label: this.$t("list.city"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Operator_name"),
          // label: this.$t("list.Merchant_Name"),
          width: "240",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "120",
        },
        {
          prop: "parkType", // TODO
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
          formatter: (row) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return this.$t("list.Unlicensed_car");
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "140",
        },
        {
          prop: "money", // 订单欠费查询
          label: this.$t("list.arrears"),
          width: "100",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        // 3.5.1
        {
          prop: "payMoney", // TODO 已缴欠费查询
          label: this.$t("list.Paid_amount_yuan"),
          width: "120",
          formatter: (row) => {
            // payMoney 注意字段
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "payTime", // TODO 支付时间
          label: this.$t("list.payment_time"),
          width: "160",
        },
      ],
      tableData: [],
      formInline: {
        // parkRecordId: '', // TODO 订单ID 3.5.1
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        carId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "",
        endTime: "",
        name: "停车欠费订单",
        dataSource: 1,
        parkName: "",
        berthCode: "",
        // fromRecord: '', // TODO 欠费区间
        // toRecord: '' // TODO 欠费区间
      },
      exportData: {},
      areaIds: "",
      tenTh: 10 ** (2 + 4),
      totalObj: {},
    };
  },
  filters: {
    /**
     * 格式化数据和单位
     * @param {参数说明} key
     */
    formatValue(value, tenTh, type) {
      value = value || 0;
      value = value / tenTh;
      if (type === "number") {
        value = value >= 1 ? value : value * 10 * 4;
        value = value.toFixed(2);
      } else {
        value = value >= 1 ? "万元" : "元";
      }
      return value;
    },
  },
  watch: {},
  computed: {
    ...mapState({
      elTabs: (state) => state.tabs.elTabs, // 页面内tab记录
    }),
    // 车场类型字段
    parkTypeInfo() {
      let keys = {};
      this.parkType.reduce((pre, { code, desc }) => {
        pre[code] = desc;
        return pre;
      }, keys);
      return keys;
    },
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号",
        },
      ];
    },
    tabTitle() {
      return { order: "订单欠费查询", pay: "已缴欠费查询" }[this.recordTab];
    },
    routePath() {
      return this.$route.path;
    },
    elItems() {
      let tabTypes = {
        order: ["payMoney", "payTime"],
        pay: ["money"],
      };
      return tabTypes[this.recordTab];
    },
  },
  methods: {
    ...mapActions({
      setTitleAndTab: "tabs/setTitleAndTab", // 设置currentTabs中当前路由标题、tab
    }),
    getTableValue({ row, prop, formatter }) {
      let value = row[prop];
      if (formatter) {
        return formatter(row);
      }
      return prop === "parkType" ? this.parkTypeInfo[value] : value;
    },
    /**
     * 处理日期
     * @param {日期值} value
     */
    formatDate(value) {
      return value < 10 ? `0${value}` : value;
    },
    /**
     * 获取区域ID
     */
    getAreaId() {
      let areaId = "";
      try {
        const {
          cascader: { getAreaId },
        } = this.$refs;
        areaId = getAreaId();
      } catch {}
      return areaId;
    },
    /**
     * alert提示
     * @param {提示内容} msg
     * @param {提示标题} title
     * @param {确认按钮内容} confirmButtonText
     */
    alert({ msg, title = this.$t('pop_up.Tips'), confirmButtonText = "确定" }) {
      this.$alert(msg, title, {
        confirmButtonText,
      });
    },
    /**
     * 控制表头列表部分数据显示隐藏
     * @param {表头属性} prop
     */
    showElFun(prop) {
      return !this.elItems.includes(prop);
    },
    /**
     * 订单欠费/已交欠费切换
     */
    handleTabFun() {
      let { tabTitle: title, routePath: path, recordTab: tab } = this;
      this.setTitleAndTab({ title, path, tab });
    },
    handleExportFile() {
      let data = cloneDeep(this.formInline);
      data.areaId = this.getAreaId();
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/debtOrder/export",
        methods: "get",
        data: data,
      };
    },
    handleAreaChange() {
      this.areaIds = this.getAreaId();
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month = this.formatDate(nowDate.getMonth() + 1);
      let day = this.formatDate(nowDate.getDate());
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange([[startTime, endTime], shortcutKey], type) {
      if (type === "period") {
        Object.assign(this.formInline, { startTime, endTime });
      } else {
        // 已交欠费查询
        // do something
      }
      if (shortcutKey) {
        this.searchData();
      }
    },
    dialogShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.getDebtMoney();
      });
    },
    getDebtMoney() {
      this.$axios.get("/acb/2.0/bigDebtRule/getLatestRecord").then((res) => {
        this.arrearsForm.debtMoney = res.value.debtMoney
          ? (res.value.debtMoney / 100).toFixed(2)
          : "";
      });
    },
    submit() {
      this.$refs.form.validate((v) => {
        if (v) {
          this.dialogVisible = false;
          let debtMoney = parseFloat(
            (this.arrearsForm.debtMoney * 100).toPrecision(12)
          );
          this.$axios
            .post("/acb/2.0/bigDebtRule/create", {
              data: {
                debtMoney,
              },
            })
            .then(() => {
              this.$message.success("操作成功！");
            });
        }
      });
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    setData() {},
    resetForm() {
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "",
        endTime: "",
        name: "停车欠费订单",
        dataSource: 1,
        parkName: "",
        berthCode: "",
      };
      // TODO 3.5.1
      if (this.show351) {
        this.formInline = {
          areaId: "",
          streetId: "",
          operationId: "",
          parkId: "",
          parkType: "-1",
          plateNumber: "",
          entryExitType: 1,
          startTime: "",
          endTime: "",
          name: "停车欠费订单",
          dataSource: 1,
          parkName: "",
          berthCode: "",
          parkRecordId: "", // TODO 订单ID
          fromRecord: "", // TODO 欠费区间
          toRecord: "", // TODO 欠费区间
        };
      }
      const { timePickerPay, timeRangePicker, cascader } = this.$refs;
      timeRangePicker?.resetTime();
      timePickerPay?.resetTime();
      cascader?.clear();
      this.$nextTick(() => {
        // 导出数据
        this.exportData = {
          url: "/acb/2.0/debtOrder/export",
          methods: "get",
          data: this.formInline,
        };
      });
      this.pageNum = 1;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    areaChange(item) {
      this.formInline.parkName = "";
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    /**
     * 选择道路名称
     * @param {选择的道路下拉数据} item
     */
    handleSelectPark({ parkId, parkName }) {
      merge(this.formInline, { parkName, parkId });
    },
    handleInputPark(val) {
      if (!val) {
        this.formInline.parkId = "";
      }
    },
    /**
     * 选择车牌号
     * @param {选择的车牌号下拉数据} item
     */
    handleSelect({ plateNumber, carId }) {
      merge(this.formInline, { plateNumber, carId });
      this.pageNum = 1;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 初始化用户区域下拉列表
    // getArea() {
    //   this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
    //     if (res.value.depth == 3) {
    //       this.areaList = res.value.areaList;
    //       this.formInline.areaId = "";
    //     } else if (res.value.depth == 4) {
    //       this.areaList2 = res.value.areaList;
    //       this.formInline.areaId = "";
    //     }
    //   });
    // },
    // 获取默认情况下的各种下拉列表
    async getParkSpinner() {
      try {
        let res = await this.$axios.get("/acb/2.0/park/spinner");
        this.parkType = get(res, "value.parkType", []);
      } catch (error) {
        console.log("spinner error:", error);
      }
      // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
      //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
      // }
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        // var time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        // time = time / (24 * 60 * 60 * 1000);
        // if (time >= 31 && this.formInline.carId == "") {
        //   this.$alert("查询时间不能大于31天");
        //   return false;
        // } else {
        return true;
        // }
      } else {
        this.alert({ msg: "日期不能为空" });
        return false;
      }
    },
    getChildrenArea() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: this.formInline.areaId,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    /* 道路 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.carId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            slaveRelations: "0,1",
            dataSource: 1,
            operationId: this.formInline.operationId,
            areaIds:
              this.formInline.streetId != ""
                ? this.formInline.streetId
                : this.formInline.areaId,
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: ({ state, desc: msg, value }) => {
          this.loading = false;
          if (state == 0) {
            this.roleList = value;
          } else {
            this.alert({ msg });
          }
        },
      };
      this.$request(opt);
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: ({ state, value }) => {
          if (state == 0) {
            this.ExitTypeList = value;
          } else {
            this.alert({ msg: "获取出入场类型失败" });
          }
        },
      };
      this.$request(opt);
    },

    /**
     * 统计结果
     */
    getTotalList(data) {
      let opt = {
        method: "get",
        url: "/acb/2.0/debtOrder/total",
        data,
        success: ({ state, value, msg }) => {
          if (state == 0) {
            this.cardLists[0].number = value.parkRecordNum || 0;
            this.cardLists[1].number = this.formatValue(
              value.money,
              this.tenTh,
              "number"
            );
            this.cardLists[1].unit = this.formatValue(
              value.money,
              this.tenTh,
              "unit"
            );
            this.totalObj = value || {};
          } else {
            this.alert({ msg });
          }
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    formatValue(value, tenTh, type) {
      value = value || 0;
      value = value / tenTh;
      if (type === "number") {
        value = value >= 1 ? value : value * 10000;
        value = value.toFixed(2);
      } else {
        value = value >= 1 ? "万元" : "元";
      }
      return value;
    },
    // 搜索
    searchData() {
      const {
        getAreaId,
        showLog,
        formInline,
        formInline: {
          parkName,
          plateNumber: { length },
        },
        pageNum: page,
        pageSize,
      } = this;
      if (length < 3) {
        formInline.carId = "";
      }
      let flag = showLog();
      let data = merge({}, formInline, {
        page,
        pageSize,
        areaId: getAreaId(),
        dataSource: 1,
      });
      let opt = {
        method: "get",
        url: "/acb/2.0/debtOrder/list",
        // data: {
        //   page: this.pageNum,
        //   pageSize: this.pageSize,
        //   parkType: this.formInline.parkType,
        //   startTime: this.formInline.startTime,
        //   endTime: this.formInline.endTime,
        //   entryExitType: this.formInline.entryExitType,
        //   areaId: areaId,
        //   operationId: this.formInline.operationId,
        //   parkId: this.formInline.parkId,
        //   carId: this.formInline.carId,
        //   plateNumber: this.formInline.plateNumber,
        //   dataSource: 1,
        //   berthCode: this.formInline.berthCode
        // },
        data,
        success: ({ state, value, msg }) => {
          if (state == 0) {
            let { total, list } = value;

            // 3.5.1
            // 格式化城市和区域
            if (this.show351) {
              list.forEach((item) => {
                let { areaName: area } = item;
                let [cityName = "", areaName = ""] = area?.split("-") || [];
                Object.assign(item, { cityName, areaName });
              });
            }

            this.tableData = setIndex(this.pageNum, list);
            this.total = total * 1 || 0;
            this.getTotalList(opt.data);
          } else {
            this.alert({ msg });
          }
          this.loading = false;
        },
        fail: (res) => {
          this.tableData = [];
          this.totalObj = {};
          this.loading = false;
        },
      };
      if (flag) {
        this.loading = true;
        this.formInline.parkName = parkName;
        this.$request(opt);
      }
    },
  },
  created() {
    // this.$route.meta.keepAlive = true;
    // this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData();
    this.getParkSpinner();
    // this.getMerchant();
    // this.getPay();
    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
  },
  mounted() {
    // TODO 3.5.1tab切换
    if (this.show351) {
      this.recordTab = this.elTabs[this.routePath.path] || "order";
      this.handleTabFun();
    }

    // 处理异步组件加载完成进行搜索操作，用于避免异步组件中存在数据操作情况如timePicker处理默认数据
    Promise.all([aAutoComplete(), aSelect(), aTimePicker()]).then(() => {
      this.searchData();
    });

    // this.searchData();
    this.$nextTick(() => {
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/debtOrder/export",
        methods: "get",
        data: this.formInline,
      };
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  // background: #fff
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;

    // padding 22px 22px 0px
    ::v-deep {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            width: 90px;
          }

          // .inline-input,
          // .el-input
          // width 200px
          .record-from-to {
            &>span {
              padding: 0 5px;
            }

            .inline-input, .el-input {
              width: 88px;
            }
          }
        }
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tableWrapper {
  padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;

  .item-wrapper {
    min-width: 88px;
    margin-bottom: 5px;
    margin-right: 16px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
