<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2022-05-19 10:46:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\components\tabs\index.vue
-->
<template>
  <div class="tab_card" style="margin-left: 24px">
    <div class="all_data">
      <p class="all_data_p">统</p>
      <p class="all_data_p">计</p>
    </div>
    <el-tabs closable type="card">
      <el-tab-pane :key="item.path" v-for="item in cardLists">
        <span class="tab_card_box" slot="label">
          <p class="card_title">{{ item.title }}</p>
          <p class="card_mess">
            {{ item.number }}<span class="card_nuit">{{ item.unit }}</span>
          </p></span
        >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    cardLists: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.tab_card {
  position: relative;
}
.tab_card /deep/ .el-tabs__nav-prev:hover {
  background: #f1f6ff;
}
.tab_card /deep/ .el-tabs__nav-prev:hover .el-icon-arrow-left {
  color: #0f6eff;
}
.tab_card /deep/ .el-tabs__nav-next:hover {
  background: #f1f6ff;
}
.tab_card /deep/ .el-tabs__nav-next:hover .el-icon-arrow-right {
  color: #0f6eff;
}
.tab_card /deep/ .el-tabs__nav-wrap.is-scrollable {
  padding: 0 28px;
}
.all_data {
  position: absolute;
  top: 0;
  left: -24px;
  height: 54px;
  width: 16px;
  background-color: #f2f3f5;
}
.all_data_p {
  height: 27px;
  width: 16px;
  text-align: center !important;
  line-height: 27px !important;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #4e5969;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
