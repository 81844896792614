var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab_card", staticStyle: { "margin-left": "24px" } },
    [
      _vm._m(0),
      _c(
        "el-tabs",
        { attrs: { closable: "", type: "card" } },
        _vm._l(_vm.cardLists, function (item) {
          return _c("el-tab-pane", { key: item.path }, [
            _c(
              "span",
              {
                staticClass: "tab_card_box",
                attrs: { slot: "label" },
                slot: "label",
              },
              [
                _c("p", { staticClass: "card_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _c("p", { staticClass: "card_mess" }, [
                  _vm._v(" " + _vm._s(item.number)),
                  _c("span", { staticClass: "card_nuit" }, [
                    _vm._v(_vm._s(item.unit)),
                  ]),
                ]),
              ]
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "all_data" }, [
      _c("p", { staticClass: "all_data_p" }, [_vm._v("统")]),
      _c("p", { staticClass: "all_data_p" }, [_vm._v("计")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }